
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { ScreenDetail } from '@/types/screen'

@Component({
  name: 'ScreenList'
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    screenName: '',
    customerName: ''
  }

  private loading = false
  private info: Array<ScreenDetail> = []

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.screen.selectCustomerScreenByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.info = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  screenAdd () {
    this.$router.push({
      name: 'screenAdd'
    })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'screenDetail',
      params: { id }
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'screenEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该大屏吗？', '提示').then(() => {
      this.$axios.post(this.$apis.screen.deleteDataCustomerScreen, {
        id: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
